import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  ContactForm,
  BasicCarousel,
  FadeReveal,
} from "@bluefin/components";
import { Grid, p } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class PrivateEventsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Image
            src={
              "https://fisherman.gumlet.io/public/e0eb2271-4a9a-4a23-ad43-84027156406e/thai-floating-market-edit-1.jpg"
            }
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"12"} textAlign={"left"}>
              <div className={"contact-form-container"}>
                <ContactForm
                  constructMessage={({
                    name,
                    email,
                    eventDate,
                    eventTime,
                    phone,
                    ext,
                    company,
                    guest,
                    eventType,
                    message,
                    source,
                  }) =>
                    `Someone has reached out to you via your website Private Events form: Name: ${name}, Email: ${email}, Phone: ${phone}, Ext: ${ext}, Company: ${company}, Number of People: ${guest}, Event Date: ${eventDate}, Event Time: ${eventTime} Event Type: ${eventType}, Additional Information: ${message}, How did you hear about us: ${source}`
                  }
                  fields={[
                    {
                      label: "name",
                      placeholder: "Full Name",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "email",
                      displayLabel: "Email",
                      placeholder: "Email",
                      type: "input",
                      inputType: "email",
                      required: true,
                    },
                    {
                      label: "phone",
                      displayLabel: "Phone Number",
                      placeholder: "Phone Number",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "ext",
                      displayLabel: "Ext",
                      placeholder: "Ext",
                      type: "input",
                      required: false,
                    },
                    {
                      label: "company",
                      displayLabel: "Company",
                      placeholder: "Company",
                      type: "input",
                      required: false,
                    },
                    {
                      label: "eventType",
                      displayLabel:
                        "Nature of this Event* (e.g., Birthday Party or Business Dinner)",
                      placeholder:
                        "Nature of this Event* (e.g., Birthday Party or Business Dinner)",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "eventDate",
                      displayLabel: "Event Date",
                      placeholder: "Event Date",
                      type: "input",
                      inputType: "date",
                      required: true,
                    },
                    {
                      label: "eventTime",
                      displayLabel: "Event Time",
                      placeholder: "Event Time",
                      type: "input",
                      inputType: "time",
                      required: true,
                    },
                    {
                      label: "guest",
                      displayLabel: "Number Of People",
                      placeholder: "Number Of People",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "message",
                      displayLabel:
                        "Is there any additional information you would like to add?",
                      placeholder: "Additional Information",
                      type: "textarea",
                      required: false,
                    },
                    {
                      label: "source",
                      displayLabel: "How did you hear about us",
                      placeholder: "How did you hear about us",
                      type: "select",
                      required: true,
                      options: [
                        { label: "EventUp", value: "EventUp" },
                        { label: "Instagram", value: "Instagram" },
                        { label: "Facebook", value: "Facebook" },
                        {
                          label: "Venues by Tripleseat",
                          value: "Venues by Tripleseat",
                        },
                        { label: "Search Engine", value: "Search Engine" },
                        { label: "Email", value: "Email" },
                        { label: "Other", value: "Other" },
                      ],
                    },
                  ]}
                  buttonText={"Send"}
                  centeredHeaders={true}
                  className={""}
                  fluidButton={true}
                  header={""}
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={true}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                  destinationEmail={"mickey@atchanas.com"}
                />
              </div>
            </Grid.Column>
          </Grid>
          <div className={"product-page"}>
            <Grid
              stackable={true}
              doubling={true}
              columns={2}
              reversed={"mobile"}
              verticalAlign={"middle"}
            >
              <Grid.Column width={9} className={"image-column"}>
                <div className={"image-container"}>
                  <BasicCarousel
                    className={""}
                    slide={true}
                    size={1}
                    displayIndicators={false}
                    displayPlayPauseButton={false}
                    autoScroll={false}
                    animation={{
                      name: "blur",
                      duration: 200,
                      transitionProps: {
                        preAnimating: { opacity: 0.99, blur: "2px" },
                        animating: { opacity: 0.99, blur: "2px" },
                      },
                    }}
                    mobile={{ size: 1 }}
                    items={[
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/e0eb2271-4a9a-4a23-ad43-84027156406e/4V0A1858_59_60.jpeg"
                          }
                          alt={"image"}
                        />
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/e0eb2271-4a9a-4a23-ad43-84027156406e/IMG_0093.jpeg"
                          }
                          alt={"image"}
                        />
                      </div>,
                      <div>
                        <div className={"image-overlay"} />
                        <Image
                          src={
                            "https://fisherman.gumlet.io/public/e0eb2271-4a9a-4a23-ad43-84027156406e/4V0A1325_6_7.jpeg"
                          }
                          alt={"image"}
                        />
                      </div>,
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={7} className={"product-details-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <p>
                    Here's our room for events, a beautifully designed space
                    with modern decor, natural light, and a cozy
                    ambiance—perfect for gatherings, parties, and special
                    occasions. Make your next event unforgettable with us!
                  </p>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Private Events" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
